import React from "react";
import ContentContainer from "../../../components/container/ContentContainer";
import Footer from "../../../components/sections/Footer";
import Header from "../../../components/sections/Header";
import HeadData from "../../../data/HeadData";
import { useStaticQuery, graphql } from "gatsby";
import TitleDiv from "../../../components/controls/TitleDiv";
import ButtonAction from "../../../components/controls/ButtonAction";
import Thumbnail from "../../../components/controls/Thumbnail";
import SimpleReactLightbox from "simple-react-lightbox";

const HardwoodLumberPage = () => {
  const images = useStaticQuery(graphql`
    {
      backGround: file(relativePath: { eq: "hardwood-lumber-banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      domesticThumb: allFile(
        filter: { relativeDirectory: { eq: "hardwood-lumber/domestic" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      importThumb: allFile(
        filter: { relativeDirectory: { eq: "hardwood-lumber/import" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `);
  const path = ["Hardwood Lumber"];

  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <HeadData title="Hardwood Lumber" />
      <Header />

      <TitleDiv
        parentText="Products"
        title="Hardwood PlyLumberood"
        className="mb-8"
        path={path}
      />

      <ContentContainer
        className="w-full max-w-content md:mx-5 bg-center flex items-center justify-center"
        opacityClassName="bg-opacity-60"
        image={images.backGround}
      >
        <div className="text-white my-20 text-center text-3xl space-y-4 w-full max-w-xl">
          <p className="font-bold">Hardwood Lumber</p>
          <p className="text-base pt-5 px-12">
            We stock both domestic and import hardwoods in a variety of species,
            grades, and types.
          </p>
          <ButtonAction to="/contact" className="mt-10 bg-actionBlue px-16">
            Request free quote
          </ButtonAction>
        </div>
      </ContentContainer>

      <ContentContainer className="bg-gray5 text-center py-12 mt-14">
        <h2>Collection</h2>
      </ContentContainer>
      <ContentContainer className="bg-gray2 py-2 mb-9">
        <div className="flex flex-col text-center items-center px-5 md:px-20 py-20">
          <p className="text-3xl font-raleway font-bold">Domestic Hardwood</p>
          <hr className="border-blue4 border-t-2 mt-4 mb-3 w-56" />
          <p>Available in 4/4 to 12/4</p>
          <SimpleReactLightbox>
            <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full mt-20 gap-5 md:gap-x-14 md:gap-y-11 items-start">
              {images.domesticThumb.edges.map((thumbnail, index) => {
                return (
                  <Thumbnail
                    key={`gallery${index}`}
                    hiRes={
                      thumbnail.node.childImageSharp.gatsbyImageData.images
                        .fallback.src
                    }
                    name={thumbnail.node.name}
                    thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                    thumbnailClassName="max-h-26 min-h-26 md:max-h-52 md:min-h-52"
                  />
                );
              })}
            </div>
          </SimpleReactLightbox>
        </div>
      </ContentContainer>

      <ContentContainer className="bg-gray5 text-center py-12 mt-14">
        <h2>Collection</h2>
      </ContentContainer>
      <ContentContainer className="bg-gray2 py-2 mb-9">
        <div className="flex flex-col text-center items-center px-5 md:px-20 py-20">
          <p className="text-3xl font-raleway font-bold">
            Import / Exotic Hardwood
          </p>
          <hr className="border-blue4 border-t-2 mt-4 mb-3 w-56" />
          <p>Available in 4/4 to 12/4</p>
          <SimpleReactLightbox>
            <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full mt-20 gap-5 md:gap-x-14 md:gap-y-11 items-start">
              {images.importThumb.edges.map((thumbnail, index) => {
                return (
                  <Thumbnail
                    key={`gallery${index}`}
                    hiRes={
                      thumbnail.node.childImageSharp.gatsbyImageData.images
                        .fallback.src
                    }
                    name={thumbnail.node.name}
                    thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                    thumbnailClassName="max-h-26 md:max-h-52"
                  />
                );
              })}
            </div>
          </SimpleReactLightbox>
        </div>
      </ContentContainer>

      <Footer enableRequestQuote={false} />
    </div>
  );
};

export default HardwoodLumberPage;
